/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js';  // Included with Angular CLI.

// necessary for aws amplify to work appropriately
// https://github.com/aws-amplify/amplify-js/issues/678#issuecomment-389106098
(window as any).global = window;

/***************************************************************************************************
 * APPLICATION IMPORTS
 */
